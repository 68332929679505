import React, { useState, useEffect } from 'react';

const NameInputModal = ({ item, onConfirm, onCancel }) => {
  const [personName, setPersonName] = useState('');
  const [previousNames, setPreviousNames] = useState([]);

  useEffect(() => {
    // Retrieve previous names from local storage for this specific item
    const storedNames = JSON.parse(localStorage.getItem(`previousNames_${item}`)) || [];
    setPreviousNames(storedNames);
  }, [item]);

  const handleConfirm = () => {
    if (personName.trim()) {
      // Save the new name to local storage for this specific item
      const updatedNames = [personName.trim(), ...previousNames.filter(name => name !== personName.trim())];
      localStorage.setItem(`previousNames_${item}`, JSON.stringify(updatedNames));
      setPreviousNames(updatedNames);
      onConfirm(personName.trim());
    } else {
      alert('Anna nimi kiitos.');
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleNameClick = (name) => {
    setPersonName(name);
  };

  // Determine h3 text and placeholder based on the item type
  const getModalText = () => {
    switch (item) {
      case 'stair':
        return { title: 'Anna portaalle nimi', placeholder: "Esim. 'Yläporras' tai 'Porras'" };
      case 'straightSteps':
        return { title: 'Anna suorille askelmille nimi', placeholder: "Esim. 'Askelmat 2' tai 'Alasyöksy'" };
      case 'platforms':
        return { title: 'Anna tasolle nimi', placeholder: "Esim. 'Taso 1'" };
      case 'squarePlatforms':
        return { title: 'Anna neliötasolle nimi', placeholder: "Esim. 'Neliötaso 1'" };
      case 'handrails':
        return { title: 'Anna käsijohteille nimi', placeholder: "Esim. 'Seinälle' tai 'Portaaseen'" };
      default:
        return { title: 'Anna nimi', placeholder: 'Anna nimi...' };
    }
  };

  const { title, placeholder } = getModalText();

  return (
    <div className="person-input-modal">
      <div className="person-input-window">
        <h3>{title}</h3>
        <input
          type="text"
          value={personName}
          onChange={(e) => setPersonName(e.target.value)}
          placeholder={placeholder}
        />
        <div className="buttons">
          {previousNames.map((name, index) => (
            <button
              key={index}
              className="previous-name-button"
              onClick={() => handleNameClick(name)}
            >
              {name}
            </button>
          ))}
        </div>
        <div className="actions">
          <button
            className="form-button form-button-cancel"
            style={{ color: 'white' }}
            onClick={handleCancel}
          >
            Peru
          </button>
          <button
            className="form-button form-button-submit"
            style={{ color: 'white' }}
            onClick={handleConfirm}
          >
            Hyväksy
          </button>
        </div>
      </div>
    </div>
  );
};

export default NameInputModal;
