import {
  fetchOrdersSuccess,
  fetchOrdersFailure,
  fetchOrderSuccess,
  fetchOpenOrdersSuccess,
  fetchOpenOrdersFailure,
  fetchOpenOrdersRequest,
  fetchOrderFailure,
  updateOrderSuccess,
  updateOrderFailure,
  fetchOrdersRequest,
  fetchOrderRequest,
  updateOrderRequest,
  fetchAllOrdersSuccess,
  fetchAllOrdersFailure,
  fetchAllOrdersRequest,
} from '../ordersAction';
import { addNotification } from '../notificationAction';
import axiosConfig from '../../../utils/axiosConfig';
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchOrders = (status, startDate, endDate, limit) => async (dispatch) => {
  try {
    dispatch(fetchOrdersRequest()); // Dispatch request action before fetching orders
    dispatch(addNotification('order', 'loading', 'Haetaan tilauksia...'));

    let url = `${apiUrl}/orders/?status=${status}&limit=${limit}`;
    if (startDate) url += `&startDate=${startDate.toISOString()}`;
    if (endDate) url += `&endDate=${endDate.toISOString()}`;

    const response = await axiosConfig.get(url);
    dispatch(fetchOrdersSuccess(response.data));
    dispatch(addNotification('order', 'success', 'Tilaukset haettu onnistuneesti'));
  } catch (error) {
    dispatch(fetchOrdersFailure(error.message));
    dispatch(addNotification('order', 'error', error.message));
  }
};

export const fetchOpenOrders = () => async (dispatch) => {
  try {
    dispatch(fetchOpenOrdersRequest()); // Dispatch request action before fetching orders
    dispatch(addNotification('order', 'loading', 'Haetaan tilauksia...'));

    let url = `${apiUrl}/orders/?status=${'OPEN'}&limit=${undefined}`;


    const response = await axiosConfig.get(url);
    dispatch(fetchOpenOrdersSuccess(response.data));
    dispatch(addNotification('order', 'success', 'Avoimet tilaukset haettu onnistuneesti'));
  } catch (error) {
    dispatch(fetchOpenOrdersFailure(error.message));
    dispatch(addNotification('order', 'error', error.message));
  }
};

export const fetchAllOrders = () => async (dispatch) => {
  try {
    dispatch(fetchAllOrdersRequest()); // Dispatch request action before fetching orders
    dispatch(addNotification('order', 'loading', 'Haetaan tilauksia Easoftista...'));

    await axiosConfig.get(`${apiUrl}/fetchOrders`);

    dispatch(fetchAllOrdersSuccess());
    dispatch(addNotification('order', 'success', 'Avoimet tilaukset haettu Easoftista'));
  } catch (error) {
    dispatch(fetchAllOrdersFailure(error.message));
    dispatch(addNotification('order', 'error', error.message));
  }
};

export const fetchOrder = (orderId) => async (dispatch) => {
  try {
    dispatch(fetchOrderRequest()); // Dispatch request action before fetching order
    dispatch(addNotification('order', 'loading', 'Haetaan tilausta...')); // Dispatch loading notification for fetching order

    const response = await axiosConfig.get(`${apiUrl}/order/${orderId}`);

    dispatch(fetchOrderSuccess(response.data)); // Dispatch success action with order data
    dispatch(addNotification('order', 'success', 'Tilaus haettu onnistuneesti'));
  } catch (error) {
    console.error('Failed to fetch order:', error.message);
    dispatch(fetchOrderFailure(error.message)); // Dispatch failure action with error message
    dispatch(addNotification('order', 'error', error.message)); // Dispatch error notification for fetching order failure
  }
};

export const fetchOrdersBySearchTerm = (searchTerm, limit) => async (dispatch) => {
  try {
    // Dispatch an action to indicate that fetching orders has started
    dispatch(fetchOrdersRequest());
    dispatch(addNotification('order', 'loading', 'Haetaan tilauksia...'));
    // Make an API call to fetch orders based on the search term and limit
    const response = await axiosConfig.get(`${apiUrl}/orders?searchTerm=${searchTerm}&limit=${limit}`);

    // Extract the orders from the response
    const orders = response.data;

    // Dispatch an action to indicate that fetching orders was successful and pass the orders data
    dispatch(fetchOrdersSuccess(orders));
    dispatch(addNotification('order', 'success', 'Tilaukset haettu onnistuneesti'));
  } catch (error) {
    // Dispatch an action to indicate that fetching orders has failed and pass the error message
    dispatch(fetchOrdersFailure(error.message));
    dispatch(addNotification('order', 'error', error.message));
  }
};

export const updateOrder = (orderId, formData) => async (dispatch) => {
  try {
    dispatch(updateOrderRequest()); // Dispatch request action before updating order
    dispatch(addNotification('order', 'loading', 'Päivitetään tilausta...'));
    console.log("Päivitetään:", formData);
    const response = await axiosConfig.put(`${apiUrl}/order/${orderId}`, formData);

    dispatch(updateOrderSuccess(response.data));
    dispatch(addNotification('order', 'success', 'Tilaus päivitetty onnistuneesti'));
  } catch (error) {
    console.error('Failed to update order:', error.message);
    dispatch(updateOrderFailure(error.message));
    dispatch(addNotification('order', 'error', error.message));
  }
};