import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOpenOrders, fetchAllOrders } from '../redux/actions/thunks/Orders';
import { Link } from 'react-router-dom';
import hazardImage from '../images/hazard.png';
import infoIcon from '../images/info.png';
import {
  startOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  isThisWeek,
  isWithinInterval,
  endOfWeek,
  format,
} from "date-fns";
import { fetchStoragePrediction, fetchResourcePrediction } from '../redux/actions/thunks/Storage';
import Tooltip from './ToolTip';
import { fetchWeeks } from '../redux/actions/thunks/SpentResources';
import { fetchUser } from '../redux/actions/thunks/Auth.js';
import { FaCheck, FaTimes } from 'react-icons/fa'; // FontAwesome icons for success and error
import LoadingIcon from './LoadingIcon'; // Import your loading spinner component

const Calendar = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));

  const orders = useSelector(state => state.orders.openOrders);
  const resourceWeeks = useSelector((state) => state.spentResources.weeks);
  const currentUser = useSelector((state) => state.users.currentUser);
  const storagePrediction = useSelector((state) => state.storage.storagePrediction);
  const resourcePrediction = useSelector((state) => state.storage.resourcePrediction);
  const loadingOrders = useSelector(state => state.orders.loading);
  const [loading, setLoading] = useState(false);
  const notifications = useSelector(state => state.notification);
  const [showIcon, setShowIcon] = useState('refresh'); // Controls what to display inside the button
  const dispatch = useDispatch();


  useEffect(() => {
    console.log(notifications.message);
    if (notifications.message === 'Avoimet tilaukset haettu Easoftista') {
      setLoading(false);
      setShowIcon('success'); // Show checkmark
      setTimeout(() => {
        setShowIcon('refresh'); // Return to refresh icon after 5 seconds
      }, 5000);
    } else if (notifications.requestStatus === 'error') {
      setLoading(false);
      setShowIcon('error'); // Show red "X"
      setTimeout(() => {
        setShowIcon('refresh'); // Return to refresh icon after 5 seconds
      }, 5000);
    }
  }, [notifications]); // Dependency array to track notification changes

  useEffect(() => {
    dispatch(fetchStoragePrediction());
    dispatch(fetchResourcePrediction());
    dispatch(fetchUser());
    dispatch(fetchWeeks());
    dispatch(fetchOpenOrders());
  }, [dispatch]);

  useEffect(() => {
    setLoading(loadingOrders);
  }, [loadingOrders]); // Dependency array to track notification changes


  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentWeek(currentWeek - 1);
      setCurrentMonth(subWeeks(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentWeek(currentWeek + 1);
      setCurrentMonth(addWeeks(currentMonth, 1));
    }
  };

  const getColorForOrder = (order) => {
    if (order.stairs.length > 0) {
      if (order.stairs[0].stepPackaging) {
        return '#90EE90';
      } else if (order.stairs[0].stepColoring1) {
        return '#ADD8E6';
      } else if (order.stairs[0].stepSanding) {
        return 'yellow';
      } else if (order.stairs[0].stepGluing) {
        return '#FFB6C1';
      } else if (order.stairs[0].stepCutting) {
        return 'violet';
      } else {
        return '#e0e0e0';
      }
    }
    else {
      return '#e0e0e0';
    }
  };

  const areMaterialsMissing = (order) => {
    if (order.stairs.length > 0) {
      // Loop through each stair in the stairs array
      for (let stair of order.stairs) {
        // Check if any of the materials are present
        if (
          stair.straightSteps.length > 0 ||
          stair.platforms.length > 0 ||
          stair.squarePlatforms.length > 0 ||
          stair.handrails.length > 0 ||
          stair.others.length > 0
        ) {
          return false; // Return false if any materials are found
        }
      }
    }
    return true; // Return true if no materials are found in any stairs
  };

  const renderCells = () => {
    const weeks = [];
    const weekStartDates = [];

    // Calculate the start date of each week in the current month
    for (let i = 0; i < 7; i++) {
      const weekStartDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
      const weekStart = addWeeks(weekStartDate, i);
      weekStartDates.push(weekStart);
    }

    // Determine the number of weeks to render based on screen width
    const getNumberOfWeeks = () => {
      const width = window.innerWidth;
      return width >= 1500 ? 5 : 4; // For example, 5 weeks for wide screens, 4 for narrow screens
    };

    const numberOfWeeks = getNumberOfWeeks();

    // Render each week horizontally
    for (let i = 0; i < numberOfWeeks; i++) {
      const weekStart = weekStartDates[i];
      const isCurrentWeek = isThisWeek(weekStart, { weekStartsOn: 1 }); // Check if the week is the current week

      const matchingWeek = storagePrediction.find(status =>
        isWithinInterval(new Date(status.weekStart), { start: weekStart, end: endOfWeek(weekStart, { weekStartsOn: 1 }) })
      );
      const materialsRunningOut = matchingWeek && matchingWeek.materials.roundedMaterialsRunningOut.length > 0;
      const materialsOutOfStorage = matchingWeek && matchingWeek.materials.roundedMaterialsRunningOut.some(material => material.amount < 0);

      let rowClassName = `row ${isCurrentWeek ? 'current-week' : ''}`;

      if (materialsRunningOut) {
        rowClassName += ' materials-running-out';
      }

      if (materialsOutOfStorage) {
        rowClassName = `row ${isCurrentWeek ? 'current-week' : ''} ${'materials-out-of-storage'}`;
      }

      // Filter orders for this week and exclude removed ones
      const ordersForWeek = orders.filter(order =>
        order.status !== "DELIVERED" && !order.isRemoved && isWithinInterval(new Date(order.delivery), { start: weekStart, end: endOfWeek(weekStart, { weekStartsOn: 1 }) })
      );
      // Fetch spent resources for this week
      const spentResourcesForWeek = resourcePrediction.filter(resource =>
        isWithinInterval(new Date(resource.weekStart), { start: weekStart, end: endOfWeek(weekStart, { weekStartsOn: 1 }) })
      );

      // Fetch the corresponding week data from weeks
      const correspondingWeek = resourceWeeks.find(week => week.weekNumber === format(weekStart, 'yyyy-ww'));

      // Calculate total available minutes
      const totalAvailableMinutes = correspondingWeek ? correspondingWeek.workers * 2400 : 0;

      // Render spent resources information for ToolTip content
      const spentResourcesContent = spentResourcesForWeek.map(resource => (
        <p key={resource.weekStart}>
          {resource.spentTime} / {totalAvailableMinutes} minuuttia
        </p>
      ));

      const capitalizeFirstLetter = (string) => {
        return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
      };

      const getFirstLetters = (str) => {
        // Split the string into words using whitespace as the delimiter
        const words = str.trim().split(/\s+/);

        // Map through the words and return the first letter of each
        const firstLetters = words.map(word => word.charAt(0).toUpperCase());

        // Join the first letters into a single string
        return firstLetters.join('');
      };

      const orderElements = ordersForWeek.map(order => {
        // Check if the designer contains the current username
        const isDesignerCurrentUser = order.designer && order.designer.includes(currentUser.username);

        return (
          <div key={order._id} className="order" style={{ backgroundColor: getColorForOrder(order) }}>
            <Link to={`/order/${order._id}`} className="order-link">
              {areMaterialsMissing(order) && (
                <>
                  <Tooltip
                    content={
                      <div>
                        <p style={{ fontSize: '15px', top: '1vw', height: '100%' }}>Materiaalitiedot puuttuu</p>
                      </div>
                    }
                  >
                    <img src={hazardImage} alt="Hazard" className="hazard-icon" data-tip data-for={`hazard-tooltip-${order._id}`} />
                  </Tooltip>
                </>
              )}

              {/* Apply circular class conditionally */}
              <div className={isDesignerCurrentUser ? 'designer-number' : 'number'}>
                {order.number}
              </div>

              <div className="customer-details">
                <div className="customer-name">{order.customer_name}</div>
                <div className="model-woodType">
                  {capitalizeFirstLetter(order.model)} {capitalizeFirstLetter(order.woodType)} &nbsp; {getFirstLetters(order.designer)}
                </div>
              </div>
            </Link>
          </div>
        );
      });


      const tooltipContent = (
        <div>
          {(spentResourcesContent.length > 0 && currentUser.role === 'admin') && (
            <>
              <p>Kuluva työaika tällä viikolla: </p>
              <ul>{spentResourcesContent}</ul>
            </>
          )}
          {materialsRunningOut && (
            <>
              <p>Tavaraa tämän viikon jälkeen:</p>
              <ul>
                {matchingWeek.materials.roundedMaterialsRunningOut.map((material, index) => (
                  <li key={`material-${index}`}>
                    {material.woodType === 'Käsijohteet' ? (
                      <>
                        {material.handRail_woodType} {material.handRailModel} käsijohde: {material.amount} kpl
                      </>
                    ) : (
                      <>
                        {material.woodType} {material.length} x {material.depth} x {material.thickness}mm: {material.amount} kpl
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      );

      const showTooltipIcon = (spentResourcesContent.length > 0 && currentUser.role === 'admin') || materialsRunningOut;

      weeks.push(
        <div className="col col-center" key={weekStart}>
          <div className="week-header-container">
            <div className={`week-header ${rowClassName}`}>
              <span className='week-number'>{getWeek(weekStart)}</span>
              {showTooltipIcon && (
                <Tooltip content={tooltipContent}>
                  <img src={infoIcon} alt="Info" className="info-icon" />
                </Tooltip>
              )}
            </div>
          </div>
          {orderElements}
        </div>
      );
    }

    return (
      <div className="body row">
        {weeks}
      </div>
    );
  };



  const renderFooter = () => {
    return (
      <div className="footer row">
        <div className="col-start" style={{ display: 'flex', alignItems: 'center' }}>
          {/* "Edellinen viikko" Button */}
          <div
            className="gradoGreenButton"
            style={{ marginLeft: '20px', maxWidth: '22vw' }}
            onClick={() => changeWeekHandle("prev")}
          >
            Edellinen viikko
          </div>

          {/* Refresh Button */}
          <div
            className="gradoGreenButton"
            style={{ marginLeft: '20px', width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            onClick={() => !loading && dispatch(fetchAllOrders())} // Prevent onClick when loading
            disabled={loading} // Disable button when loading is true
          >
            {loading && <LoadingIcon style={{ marginLeft: '0px' }} />} {/* Show spinner while loading */}
            {!loading && showIcon === 'refresh' && <span style={{ fontSize: '20px', color: 'white' }}>⟳</span>} {/* Refresh emoji */}
            {!loading && showIcon === 'success' && <FaCheck className="pulse-spin notification-success" style={{ fontSize: '20px', color: 'green' }} />} {/* Success checkmark */}
            {!loading && showIcon === 'error' && <FaTimes className="pulse-spin notification-error" style={{ fontSize: '20px', color: 'red' }} />} {/* Error red X */}
          </div>
        </div>
        <div className="status-container">
          <span className="status-item">
            <span className="status-block" style={{ backgroundColor: '#90EE90' }}></span>
            Pakattu
          </span>
          <span className="status-item">
            <span className="status-block" style={{ backgroundColor: '#ADD8E6' }}></span>
            Värjäyksessä
          </span>
          <span className="status-item">
            <span className="status-block" style={{ backgroundColor: 'yellow' }}></span>
            Hiottu
          </span>
          <span className="status-item">
            <span className="status-block" style={{ backgroundColor: '#FFB6C1' }}></span>
            Liimattu
          </span>
          <span className="status-item">
            <span className="status-block" style={{ backgroundColor: 'violet' }}></span>
            Leikattu
          </span>
          <span className="status-item">
            <span className="status-block" style={{ backgroundColor: '#e0e0e0' }}></span>
            Ei työvaiheita
          </span>
        </div>
        <div className="col-end">
          <div className="gradoGreenButton" style={{ marginRight: '20px', maxWidth: '22vw' }} onClick={() => changeWeekHandle("next")}>
            Seuraava viikko
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="calendar">
      {renderFooter()}
      {renderCells()}

    </div>
  );
};

export default Calendar;
