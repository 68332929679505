import React, { useState, useEffect, useRef } from 'react';
import DeleteConfirmation from './DeleteConfirmation'; // Import the confirmation modal

const CustomDropdown = ({ items, selectedIndex, onChange, onDelete, onEdit }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [editedName, setEditedName] = useState('');
    const [showConfirm, setShowConfirm] = useState(false); // State to control the confirmation modal
    const [deleteIndex, setDeleteIndex] = useState(null); // Track which item is being deleted
    const dropdownRef = useRef(null);

    const handleToggle = () => setIsOpen(!isOpen);

    const handleItemClick = (index) => {
        if (editIndex !== index) {
            onChange(index);
            setIsOpen(false);
        }
    };

    const handleDeleteClick = (index, e) => {
        e.stopPropagation(); // Prevent closing the dropdown
        setDeleteIndex(index); // Set the item to be deleted
        setShowConfirm(true); // Show confirmation modal
    };

    const handleEditClick = (index, itemName, e) => {
        e.stopPropagation(); // Prevent closing the dropdown
        setEditIndex(index);
        setEditedName(itemName);
    };

    const handleSaveEdit = (index, e) => {
        e.stopPropagation(); // Prevent closing the dropdown
        onEdit(index, editedName);
        setEditIndex(null); // Exit edit mode after saving
    };

    const handleConfirmDelete = () => {
        onDelete(deleteIndex); // Call the delete function
        setShowConfirm(false); // Hide confirmation modal
        setDeleteIndex(null); // Reset delete index
        setIsOpen(false); // Close dropdown after deletion
    };

    const handleCancelDelete = () => {
        setShowConfirm(false); // Hide confirmation modal
        setDeleteIndex(null); // Reset delete index
        setIsOpen(false); // Close dropdown when cancelling deletion
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
                setEditIndex(null); // Exit edit mode if clicked outside
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="custom-dropdown" ref={dropdownRef}>
            <div className="custom-dropdown-header" onClick={handleToggle}>
                {selectedIndex !== null ? items[selectedIndex].name : 'Select an option'}
            </div>
            {isOpen && (
                <div className="custom-dropdown-menu">
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className={`custom-dropdown-item ${index === hoveredIndex ? 'hovered' : ''}`}
                            onClick={() => handleItemClick(index)}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            {/* If item is being edited, show input field, otherwise show item name */}
                            {editIndex === index ? (
                                <input
                                    type="text"
                                    value={editedName}
                                    onChange={(e) => setEditedName(e.target.value)}
                                    onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing when clicking input
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') handleSaveEdit(index, e);
                                    }}
                                />
                            ) : (
                                item.name
                            )}
                            <div className="custom-dropdown-actions">
                                {/* Pencil icon for editing */}
                                <span
                                    className="custom-dropdown-edit"
                                    onClick={(e) => handleEditClick(index, item.name, e)}
                                >
                                    ✏️
                                </span>
                                {/* X icon for deleting */}
                                <span
                                    className="custom-dropdown-delete"
                                    onClick={(e) => handleDeleteClick(index, e)}
                                >
                                    &#10005;
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Confirmation modal for delete action */}
            {showConfirm && (
                <DeleteConfirmation
                    item={items[deleteIndex]?.name}
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
            )}
        </div>
    );
};

export default CustomDropdown;
