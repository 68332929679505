import React from 'react';

const DeleteConfirmation = ({ item, onConfirm, onCancel }) => {
  return (
    <div className="delete-confirmation-modal">
      <div className="delete-confirmation-window">
        <p className="confirmation-message">
          Haluatko varmasti poistaa tämän: {item}?
        </p>
        <div className="delete-confirmation-buttons">
          <button className="cancel" onClick={onCancel}>
            Peru
          </button>
          <button className="confirm" onClick={onConfirm}>
            Kyllä
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmation;