import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSpentResources, updateSpentResources, fetchWeeks, updateWeek } from '../redux/actions/thunks/SpentResources';
import { fetchStorage } from '../redux/actions/thunks/Storage';
import { format, startOfWeek, addWeeks, startOfISOWeek, addYears } from 'date-fns';
import { FaCheck } from 'react-icons/fa';
import LoadingIcon from './LoadingIcon';

const ModifyResources = () => {
  const dispatch = useDispatch();

  // Fetch storage and spent resources from Redux state
  const storage = useSelector((state) => state.storage.storage);
  const resources = useSelector((state) => state.spentResources.spentResources);
  const weeks = useSelector((state) => state.spentResources.weeks);
  const notifications = useSelector(state => state.notification);


  const [currentModels, setCurrentModels] = useState([]);
  const [selectedWoodTypes, setSelectedWoodTypes] = useState([]);
  const [selectedModelNames, setSelectedModelNames] = useState([]);
  const [weekValues, setWeekValues] = useState({});
  const [isSubmittedWorkers, setIsSubmittedWorkers] = useState(false);
  const [isSubmittedTimes, setIsSubmittedTimes] = useState(false);
  const [isLoadingWorkers, setIsLoadingWorkers] = useState(false);
  const [isLoadingTimes, setIsLoadingTimes] = useState(false);

  // Fetch storage, spent resources, and weeks on component mount
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchStorage());
      await dispatch(fetchSpentResources());
      await dispatch(fetchWeeks());
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (notifications.message === 'Työntekijämäärä päivitetty onnistuneesti') {
      setIsSubmittedWorkers(true);
      setIsLoadingWorkers(false);

      // Set isSubmittedWorkers to false after 5 seconds
      setTimeout(() => {
        setIsSubmittedWorkers(false);
      }, 5000);
    }
    if (notifications.message === 'Työajat päivitetty onnistuneesti') {
      setIsSubmittedTimes(true);
      setIsLoadingTimes(false);

      // Set isSubmittedWorkers to false after 5 seconds
      setTimeout(() => {
        setIsSubmittedTimes(false);
      }, 5000);
    }

    if (notifications.message === 'Päivitetään työntekijämääriä') {
      setIsLoadingWorkers(true);
    }

    if (notifications.message === 'Päivitetään työaikoja...') {
      setIsLoadingTimes(true);
    }
  }, [notifications, dispatch]);

  // Update local state with fetched week data
  useEffect(() => {
    const formattedWeekValues = {};
    const today = new Date();
    const currentWeekNumber = format(startOfWeek(today, { weekStartsOn: 1 }), 'yyyy-ww');
    weeks.forEach(week => {
      if (week.weekNumber >= currentWeekNumber) {
        formattedWeekValues[week.weekNumber] = week.workers;
      }
    });
    setWeekValues(formattedWeekValues);
  }, [weeks]);

  // Check if next 8 weeks from today exist, if not create them
  useEffect(() => {
    const today = new Date();
    for (let i = 0; i < 8; i++) {
      const nextWeek = startOfWeek(addWeeks(today, i), { weekStartsOn: 1 });
      const weekNumber = format(nextWeek, 'yyyy-ww');
      if (!weeks.find(week => week.weekNumber === weekNumber)) {
        const workers = 0;
        setWeekValues(prevValues => ({
          ...prevValues,
          [weekNumber]: workers
        }));
      }
    }
  }, [weeks]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWeekValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    // Collect all updateWeek promises
    const updatePromises = Object.entries(weekValues).map(([weekNumber, workers]) => {
      return dispatch(updateWeek({ weekNumber, workers }));
    });

    // Wait for all updates to complete and then fetch weeks
    Promise.all(updatePromises)
      .then(() => {
        dispatch(fetchWeeks());
      })
  };

  const getWeekNumberFromDate = (dateString) => {
    const [year, week] = dateString.split('-');
    const date = startOfISOWeek(addYears(new Date(0), Number(year) - 1970));
    const weekStart = addWeeks(date, Number(week) - 1);
    return format(weekStart, 'ww');
  };

  const sortedWeeks = Object.keys(weekValues).sort((a, b) => {
    const [yearA, weekA] = a.split('-').map(Number);
    const [yearB, weekB] = b.split('-').map(Number);
    if (yearA === yearB) {
      return weekA - weekB;
    }
    return yearA - yearB;
  });

  // Extract unique wood types from storage
  const woodTypes = [...new Set(storage.map(item => item.woodType).filter(type => type !== null))];

  // Handle selection of wood types
  const handleWoodTypeSelect = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
    setSelectedWoodTypes(selectedOptions);
    setSelectedModelNames([]); // Reset selected model names

    // If 'Käsijohteet' is included, remove other wood types
    if (selectedOptions.includes('Käsijohteet')) {
      setSelectedWoodTypes(['Käsijohteet']);
    }

    updateCurrentModels(selectedOptions, []);
  };

  // Handle selection of model names
  const handleModelNameSelect = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
    setSelectedModelNames(selectedOptions);
    updateCurrentModels(selectedWoodTypes, selectedOptions);
  };

  // Update current models based on selected wood types and model names
  const updateCurrentModels = (woodTypes, modelNames) => {
    let selectedModels = [];
    if (woodTypes.includes('Käsijohteet')) {
      const selectedModel = resources.find(model => model.woodType === 'Käsijohteet' && model.modelName === 'Käsijohteet');
      selectedModels = selectedModel ? [selectedModel] : [{ woodType: 'Käsijohteet', modelName: 'Käsijohteet', handRailTime: '' }];
    } else {
      selectedModels = woodTypes.flatMap(woodType =>
        modelNames.map(modelName =>
          resources.find(model => model.woodType === woodType && model.modelName === modelName) || { woodType, modelName }
        )
      );
    }
    // Check if multiple wood types and model names are selected
    const multipleWoodTypesSelected = woodTypes.length > 1;
    const multipleModelNamesSelected = modelNames.length > 1;

    // If multiple wood types or model names are selected, set all fields to be empty
    if (multipleWoodTypesSelected || multipleModelNamesSelected) {
      selectedModels = woodTypes.flatMap(woodType =>
        modelNames.map(modelName => ({
          woodType,
          modelName,
          timePerStep: '',
          platformTime: '',
          squarePlatformTime: '',
          triangleStep: ''
        }))
      );
    }

    setCurrentModels(selectedModels);
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = Math.max(Number(value), 0); // Ensure value is not below 0
    setCurrentModels((prevModels) =>
      prevModels.map((model) => ({
        ...model,
        [name]: numericValue,
      }))
    );
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    currentModels.forEach(model => dispatch(updateSpentResources(model)));
    dispatch(fetchSpentResources());
  };

  return (
    <div className="modify-resources-minutes">
      <div className="modify-resources">
        <h2 className="modify-resources__title">Muokkaa kuluvia työaikoja</h2>

        <div className="modify-resources__select">
          <select
            multiple
            className="modify-resources__select-box"
            onChange={handleWoodTypeSelect}
            value={selectedWoodTypes}
          >
            {woodTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        {selectedWoodTypes.length > 0 && !selectedWoodTypes.includes('Käsijohteet') && (
          <div className="modify-resources__select">
            <select
              multiple
              className="modify-resources__select-box"
              onChange={handleModelNameSelect}
              value={selectedModelNames}
            >
              {['Lumo', 'Kide', 'Valo', 'Leiju', 'Kannel', 'Umpiporras'].map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </select>
          </div>
        )}

        <form className="modify-resources__form" onSubmit={handleSubmit}>
          {(selectedWoodTypes.includes('Käsijohteet') || currentModels.length > 0) && (
            <button className="modify-resources__button" type="submit" disabled={isLoadingTimes}>
            {isLoadingTimes ? (
              <LoadingIcon />
            ) : (
              <>
                {isSubmittedTimes && !isLoadingTimes && (
                  <FaCheck
                    className="pulse-spin"
                    style={{
                      position: 'absolute',
                      marginLeft: '70px',
                      color: 'lightgreen',
                      fontSize: '1.2em',
                    }}
                  />
                )}
                Tallenna
              </>
            )}
          </button>
          
          )}
          {selectedWoodTypes.includes('Käsijohteet') ? (
            <>
              <div className="modify-resources__form-item">
                <label className="modify-resources__label">
                  Käsijohdesalko:
                  <input
                    type="number"
                    className="modify-resources__input"
                    name="handRailTime"
                    value={currentModels[0]?.handRailTime || 0}
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="modify-resources__unit">minuuttia</span>
                </label>
              </div>
            </>
          ) : (
            <>
              {currentModels.length > 0 && (
                <>
                  <div className="modify-resources__form-item">
                    <label className="modify-resources__label">
                      Suora askelma: <input
                        type="number"
                        className="modify-resources__input"
                        name="timePerStep"
                        value={currentModels[0]?.timePerStep || 0}
                        onChange={handleChange}
                      />
                      <span className="modify-resources__unit">minuuttia</span>
                    </label>
                  </div>
                  <div className="modify-resources__form-item">
                    <label className="modify-resources__label">
                      Kiertävä askelma: <input
                        type="number"
                        className="modify-resources__input"
                        name="triangleStep"
                        value={currentModels[0]?.triangleStep || 0}
                        onChange={handleChange}
                      />
                      <span className="modify-resources__unit">minuuttia</span>
                    </label>
                  </div>
                  <div className="modify-resources__form-item">
                    <label className="modify-resources__label">
                      Neliötaso: <input
                        type="number"
                        className="modify-resources__input"
                        name="squarePlatformTime"
                        value={currentModels[0]?.squarePlatformTime || 0}
                        onChange={handleChange}
                      />
                      <span className="modify-resources__unit">minuuttia</span>
                    </label>
                  </div>
                  <div className="modify-resources__form-item">
                    <label className="modify-resources__label">
                      Taso: <input
                        type="number"
                        className="modify-resources__input"
                        name="platformTime"
                        value={currentModels[0]?.platformTime || 0}
                        onChange={handleChange}
                      />
                      <span className="modify-resources__unit">minuuttia</span>
                    </label>
                  </div>

                </>
              )}
            </>
          )}
        </form>
      </div>
      <div className="modify-resources">
        <h2 className="modify-resources__title">Henkilöitä töissä</h2>
        <form className="modify-resources__form" onSubmit={handleSave}>
          {sortedWeeks.map(weekNumber => (
            <div key={weekNumber} className="modify-resources__form-item">
              <label className="modify-resources__label" htmlFor={weekNumber}>{`Viikko ${getWeekNumberFromDate(weekNumber)}:`}</label>
              <input
                type="number"
                id={weekNumber}
                name={weekNumber}
                value={weekValues[weekNumber]}
                className="modify-resources__input"
                onChange={handleInputChange}
              />
              <span className="modify-resources__unit">henkilöä</span>
            </div>
          ))}
          <button className="modify-resources__button" type="submit" disabled={isLoadingWorkers}>
            {isLoadingWorkers ? (
              <LoadingIcon />
            ) : (
              <>
                {isSubmittedWorkers && !isLoadingWorkers && (
                  <FaCheck
                    className="pulse-spin"
                    style={{
                      position: 'absolute',
                      marginLeft: '70px',
                      color: 'lightgreen',
                      fontSize: '1.2em',
                    }}
                  />
                )}
                Tallenna
              </>
            )}
          </button>

        </form>
      </div>

    </div>
  );
};

export default ModifyResources;
