import React, { useState, useEffect } from 'react';

const PersonInputModal = ({ item, onConfirm, onCancel }) => {
  const [personName, setPersonName] = useState('');
  const [previousNames, setPreviousNames] = useState([]);

  useEffect(() => {
    // Retrieve previous names from local storage
    const storedNames = JSON.parse(localStorage.getItem('previousNames')) || [];
    setPreviousNames(storedNames);
  }, []);

  const handleConfirm = () => {
    if (personName.trim()) {
      // Save the new name to local storage
      const updatedNames = [personName.trim(), ...previousNames.filter(name => name !== personName.trim())];
      localStorage.setItem('previousNames', JSON.stringify(updatedNames));
      setPreviousNames(updatedNames);
      onConfirm(personName.trim());
    } else {
      alert('Anna nimi kiitos');
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleNameClick = (name) => {
    setPersonName(name);
  };

  const getClassName = (item) => {
    switch (item) {
      case "Poista tuote":
        return "negative";
      case "Lisää uusi tuote":
        return "positive";
      case "Lisää muu tuote":
        return "positive";
      case "Muuta määrää":
        return "default";
      case "Muokkaa tuotetta":
        return "";
      default:
        return "";
    }
  };

  return (
    <div className="person-input-modal">
      <div className="person-input-window">
        <h1 className={getClassName(item)}>{item}</h1>
        <h3>Syötä henkilön nimi</h3>
        <input
          type="text"
          value={personName}
          onChange={(e) => setPersonName(e.target.value)}
          placeholder="Syötä nimi..."
        />
        <div className="buttons">
          {previousNames.map((name, index) => (
            <button
              key={index}
              className="previous-name-button"
              onClick={() => handleNameClick(name)}
            >
              {name}
            </button>
          ))}
        </div>
        <div className="actions">
          <button
            className="form-button form-button-cancel"
            style={{ color: 'white' }}
            onClick={handleCancel}
          >
            Peru
          </button>
          <button
            className="form-button form-button-submit"
            style={{ color: 'white' }}
            onClick={handleConfirm}
          >
            Hyväksy
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonInputModal;